import React, { Component } from 'react'
import styled from 'styled-components'
import logo from './logo.svg';
import { Container, Row, Col } from 'react-bootstrap';

const SFooter = styled.div`

.row {
    background-color: #2f96cc;
    border-bottom: 1px solid gray;
    height:60px;
    margin-top:80px;


    p {
        color: white;
        margin-top:20px;
        margin-bottom:20px;        
    }

}

`


const Footer = (props) => {

    return (

        <SFooter>
            <Container>
                <Row style={{backgroundImage: logo}} className="row">
                    <Col>                        
                        <p style={{display: 'inline', float: 'left'}}>Nusive</p>
                        <p style={{display: 'inline', float: 'right'}}>support@nusive.com</p>
                    </Col>
                </Row>
            </Container>
        </SFooter>

    );

}

export default Footer;
