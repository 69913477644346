import React, { Component } from 'react'
import styled from 'styled-components'
import logo from './logo.svg';
import { Container, Row, Col } from 'react-bootstrap';

const SHeader = styled.div`

    margin-bottom: 0px;

    h1 {
        text-align:center;
        font-size:15px;
        color: white;
        font-size: 20px;
        font-weight: bold;

        @media (min-width: 992px) {
            font-size: 33px;
        }
    }

    p {
        color:white;
        font-size:15px;

    }

    .col {
        margin-top: auto;
        margin-bottom: auto;

    }

    .row {
        background-color: #2f96cc;
        background-image: url('${logo}');
        border-bottom: 1px solid gray;
        height:300px;
        background-repeat: no-repeat;
        background-size: auto 99%;
        background-position: center left;        
        text-align: center;
    }
    

  /* ... */
`


const Header = (props) => {

    return (


        <SHeader>
            <Container>
                <Row style={{backgroundImage: logo}} className="row">
                    <Col style={{verticalAlign: 'middle'}}>
                        <h1>Sunshine Custom Objects Editor</h1>
                        <p>Create custom objects and manage connections with ease</p>
                    </Col>
                </Row>
            </Container>
        </SHeader>

    );

}

export default Header

/*
<Col style={{ textAlign: 'right' }}>
<div className="table">
    <div className="row">
        <div className="cell">
            <img className="logo" src={logo} alt="logo" />
        </div>
    </div>
</div>
</Col>
<Col style={{ textAlign: 'left' }}>
Sunshine Custom Objects Editor
</Col>
*/