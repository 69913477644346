import React from 'react';

import image_creating from './images/creating.jpg';
import image_showing_relation from './images/show_relation.jpg';
import image_unlinking from './images/unlinking.jpg';

import image_zendesk_exporter from './images/ticket-export-1.jpg';
import image_zendesk_exporter1 from './images/ticket-export-2.jpg';

import './App.css';
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import Header from './components/Header'
import Header2 from './components/Header2'
import SFooter from './components/Footer';

const Title = styled('h1')`
  text-align: center;

  text-align: center;
  font-size: 20px;
  margin-top: 42px;
  margin-bottom: 59px;

  
  @media (min-width: 992px) {
    font-size: 24px;
    margin-top: 60px;
    margin-bottom: 60px;
    }



`

const Gallery = styled(Container)`

  margin-bottom: 40px;

  img {
    width:80%;
    height:auto;
    -webkit-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.75);
  }

  .gallery-entry {
    p {
      font-size:14px;
      font-style:italic;
      margin: 25px 0px 40px 0px;
    }


    .text-col {
      text-align:center;      
    }

    div {
      margin-top: auto;
      margin-bottom: auto;
      text-align:center;
    }


    @media (min-width: 900px) {
        p {
          font-size:18px;
          margin: 0px;
        }      
    }


  }


`

/*Create, edit and manage associations.*/

function App() {
  return (
    <div className="App">

      <Header>

      </Header>

      <Container>
        <Row>
          <Col>
            <Title className="title">Sunshine Custom Objects Editor allows you to manage your custom objects the easy way</Title>
          </Col>
        </Row>
      </Container>

      <Gallery>

        <GalleryEntry
          image={<img src={image_creating} alt="Creating custom objects"></img>}
          text={<p>Zendesk Custom Objects Editor allows to create objects with a simple interface.</p>}
        />
        <GalleryEntry inverted={true}
          image={<img src={image_showing_relation} alt="Objects relationships"></img>}
          text={<p>Relations between objects are displayed as a tree.<br />Groups of children can be extended and collapsed to aid navigation.<br />Lazy loading of children makes it light and fast. </p>}
        />
        <GalleryEntry
          image={<img src={image_unlinking} alt="Unlinking children"></img>}
          text={<p>When navigating the list of children for an object, they can be unlinked or removed from there. </p>}
        />

      </Gallery> 

      <Header2>

      </Header2>

      <Container>
        <Row>
          <Col>
            <Title className="title">Export Zendesk tickets to PDF for sharing or printing</Title>
          </Col>
        </Row>
      </Container>

      <Gallery>

        <GalleryEntry
          image={<img src={image_zendesk_exporter} alt="The Zendesk PDF Exporter Interface"></img>}
          text={<p>This Ticket sidebar application presents a simple interface for exporting the open ticket to PDF.</p>}
        />
        <GalleryEntry inverted={true}
          image={<img src={image_zendesk_exporter1} alt="PDF example"></img>}
          text={<p>The exported PDF files contains all the information for the ticket neatly organized.</p>}
        />

      </Gallery>



    <SFooter></SFooter>

    </div>
  );
}

export default App;


const GalleryEntry = (props) => {
  return (
    <Row className="gallery-entry">
      <Col md={{ span: 6, order: props.inverted ? 2 : 1 }} xs={{ span: 12, order: 1 }}> {props.image}</Col>
      <Col md={{ span: 6, order: props.inverted ? 1 : 2 }} xs={{ span: 12, order: 2 }}> {props.text} </Col>
    </Row>
  );
}